import { Component }  from "react"



export class AddressView extends Component{

    render(){
        return <div style={{
            display: 'flex',
            flexDirection: 'row',
            color: 'white',
            justifyContent: 'center',
            padding: '20px 0'
          }}>
            <div style={{ width: '300px', display: 'flex', justifyContent: 'center' }}>
              <div>
                <h2>US Office</h2>
                <p style={{ fontSize: '1em' }}>Satyawati LLC</p>
                <p style={{ fontSize: '0.75em' }}>8005 Linda Michelle Ln<br />
                  Austin, TX 78724
                </p>
                <a style={{ color: 'white', fontSize: '0.75em' }} href="tel:+15127665597"> Tel: +1-(512)-766-5597</a>
              </div>
            </div>
            <div style={{ width: '300px', display: 'flex', justifyContent: 'center' }}>
              <div>
                <h2>Nepal Office</h2>
                <p style={{ fontSize: '1em' }}>Satyawati Technologies <br />
                  Pvt. Ltd.</p>
                <p style={{ fontSize: '0.75em' }}>Tilottama 6, Manigram<br />
                  Rupandehi <br />
                  Lumbini Province
                </p>
                <a style={{ color: 'white', fontSize: '0.75em' }} href="tel:+9779826466033"> Tel: +977-982-646-6033</a>
                <br />
              </div>
            </div>
          </div>
    }
}