export default {
  dark: {
    Home: {
      backgroundColor: '#1a2837'
    },
    name: 'dark',
    messages: {
      backgroundColor: '#1a2837'
    },
    bar: {
      backgroundColor: '#233448',
      borderBottom: '1px solid rgba(0,0,0,0)'
    },
    messageWrap: {
      borderBottom: '1px solid rgba(0,0,0,0.5)'
    },
    windowBar: {
      backgroundColor: '#16202d'
    },
    colors: {
      dimmed: '#8799a7',
      icon: '#72bfff',
      text: 'white'
    },
    button: {
      background: '#0B182D',
      backgroundHover: '#1C3657',
      color: '#fff'
    },
    contactbutton: {
      background: 'rgba(255, 255, 255, 0.2)',
      backgroundHover: '#4682B4',
      color: '#fff'
    },
    socialicon: {
      background: 'rgba(0, 0, 0, 0.0)',
      backgroundHover: '#4682B4',
      color: '#fff'
    },
    country: {
      background: 'rgba(0, 0, 0, 0.0)',
      backgroundHover: '#4682B4',
      color: '#fff'
    },
    composeInput: {
      backgroundColor: '#192530',
      color: 'white'
    },
    contactInput: {
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
      color: 'white'
    },
    Window: {
      backgroundColor: '#1a2836'
    },
    Bar: {
      backgroundColor: '#243547',
      borderBottom: '1px solid rgba(0,0,0,0)'
    }
  }
};
