import React from 'react';

//import { LoadScript } from 'components/Script';
import * as S from './styles';
import * as A from './../../styles/shared-components';
//icons
import faApple from '../../icons/envelope.svg';
import faAndroid from '../../icons/android.svg';
import { isDev } from '../../utils/dev-prod';

//component
import { isAndroid, isIOS, isBrowser } from 'react-device-detect';

//env
//const { REACT_APP_PADDLE_VENDOR, REACT_APP_PADDLE_PRODUCT_ID } = process.env;

function DownloadButton({ startLoading }) {
  const download = async () => {
    window.open(`https://videopasal.com`);
  };

  return (
    <S.Content>
      <br />
      <S.Button1
        onClick={download}
        role="button"
        tabIndex={0}
        onKeyPress={e => {
          if ((e.which === 13 || e.which === 32) ) {
            download(e);
          }
        }}
      >
        {/* <S.AppleIcon icon={faApple} /> */}
        <span>Download Now</span>
      </S.Button1>
    </S.Content>
  );
}

export default DownloadButton;
