import styled from 'styled-components';
import flex from './../../styles/flex';
import { applyTheme, getThemeColor, hover, when } from './../../styles/mixins';

import { isHorizontal } from './../../styles/responsive';

//components
import Icon from './../../icons/Icon';

export const Input = styled.textarea(
  {
    borderRadius: 3,
    padding: 10,
    border: 'none',
    outline: 'none',
    minHeight: 30,
    width: '100%',
    resize: 'none'
  },
  applyTheme('contactInput')
);

export const InputMessage = styled.textarea(
  {
    borderRadius: 3,
    padding: 10,
    border: 'none',
    outline: 'none',
    minHeight: 120,
    width: '100%',
    resize: 'none'
  },
  applyTheme('contactInput')
);

export const Country = styled.button(
  {
    ...flex.horizontal,
    ...flex.centerHorizontal,
    userSelect: 'none',
    border: 'none',
    fontSize: 18,
    borderRadius: 4,
    maxWidth: 200,
    padding: 15,
    width: '100%',
    transition: 'all 150ms linear',
  },
  applyTheme('country'),
  when('disabled', {
    opacity: 0.5,
    cursor: 'not-allowed'
  }),
  ({ theme }) => ({
    ...(!theme.disabled && {
      ...hover({
        background: theme.button.backgroundHover
      })
    })
  })
);

export const Button = styled.button(
  {
    ...flex.horizontal,
    ...flex.centerHorizontal,
    userSelect: 'none',
    border: 'none',
    fontSize: 18,
    borderRadius: 4,
    maxWidth: 140,
    padding: 15,
    width: '100%',
    transition: 'all 150ms linear',
    boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.2)'
  },
  applyTheme('contactbutton'),
  when('disabled', {
    opacity: 0.5,
    cursor: 'not-allowed'
  }),
  ({ theme }) => ({
    ...(!theme.disabled && {
      ...hover({
        background: theme.button.backgroundHover
      })
    })
  })
);

export const AIcon = styled(Icon)(
  {
    marginRight: 15,
    width: 20,
    height: 20,
    position: 'relative',
    top: -1
  },
  getThemeColor('text', 'fill')
);

export const Content = styled.div({
  //...flex.horizontal,
  // ...zIndexFor(ELEMENTS.CONTENT),
  marginTop: 'auto',
  marginBottom: 'auto',
  [isHorizontal]: {
    flexDirection: 'column',
    ...flex.centerVertical,
    minWidth: "300px"
  }
});

export const Map = styled.div({
  //...flex.horizontal,
  // ...zIndexFor(ELEMENTS.CONTENT),
  marginTop: 'auto',
  marginBottom: 'auto',
  [isHorizontal]: {
    flexDirection: 'column',
    ...flex.centerVertical
  }
});

export const Contact = styled.div({
  ...flex.horizontal,
  //...flex.spaceAround,
  // ...zIndexFor(ELEMENTS.CONTENT),
  marginTop: 'auto',
  marginBottom: 'auto',
  [isHorizontal]: {
    flexDirection: 'column',
    ...flex.centerVertical,
    maxWidth: window.innerWidth
  }
});

export const NameEmail = styled.div({
  ...flex.horizontal,
  // ...zIndexFor(ELEMENTS.CONTENT),
  marginTop: 'auto',
  marginBottom: 'auto',
  [isHorizontal]: {
    flexDirection: 'column',
    ...flex.centerVertical,
    maxWidth: window.innerWidth
  }
});

export const USNep = styled.div({
  ...flex.horizontal,
  // ...zIndexFor(ELEMENTS.CONTENT),
  ...flex.spaceAround,
  marginTop: 'auto',
  marginBottom: 'auto',
  [isHorizontal]: {
    flexDirection: 'column',
    ...flex.centerVertical,
    maxWidth: window.innerWidth
  }
});
