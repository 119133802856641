import { useEffect, useState } from 'react';
import { usePose, useVisiblePose } from '../../utils/hooks';
import sequence from '../../utils/sequence';

const useIntroAnimation = () => {
  // poses
  const { pose: homePose, setPose: setHomePose } = usePose('hidden', ['hidden', 'middle', 'normal']);
  const [menuBarPose, setMenubarPose] = useVisiblePose(false);
  const [isAnimationDone, setAnimationDone] = useState(false);

  useEffect(() => {
    sequence([
      0,
      () => setHomePose('hiddenCenter'),
      100,
      () => setHomePose('middle'),
      200,
      () => setHomePose('normal'),
      200,
      () => {
        setMenubarPose(true);
        setAnimationDone(true);
      }
    ]);
  }, []);

  return {
    homePose,
    menuBarPose,
    isAnimationDone
  };
};

export default useIntroAnimation;
