import React, { useEffect, useState } from 'react';

//styles
import * as S from './styles';
import * as A from './../../styles/shared-components';

function Stacker({ mainIcon, title, content }) {

  return (
    <S.Stacker>
      <S.Window>
          <S.Button>
        <S.StackImage icon={mainIcon} />
        </S.Button>
        <A.Space small />
        <div>
          <span>{title}</span>
          <p>{content}</p>
        </div>
        <A.Space huge />
      </S.Window>
    </S.Stacker>
  );
}

export default Stacker;
