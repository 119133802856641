import styled from 'styled-components';
import flex from './../../styles/flex';
import { applyTheme } from './../../styles/mixins';

import { isHorizontal } from './../../styles/responsive';

export const Window = styled('div')({
  ...flex.vertical,
  //borderRadius: 5,
  overflow: 'hidden',
  //boxShadow: '0 0 25px 8px rgba(0, 0, 0, 0.12)',
  width: '100%',
  [isHorizontal]: {
    flexDirection: 'column-reverse',
    ...flex.centerVertical
  }
});

// export const Bar = styled('div')(
//   {
//     ...flex.horizontal,
//     ...flex.centerHorizontalV,
//     padding: '0 10px',
//     height: 23,
//     minHeight: 23,
//     transition: 'all 100ms linear'
//   },
//   applyTheme('windowBar')
// );
