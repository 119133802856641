import { useState, useEffect } from 'react';

export const useCanHover = () => {
  //assume that if device is smaller than 500 there's no hover, but actually check it on the first touch event
  const [canHover, setCanHover] = useState(window.innerWidth > 500);
  useEffect(() => {
    //mobile devices also emit a "mousemove" on every touch (#theplatform<3), but desktop devices don't emit "touchstart"
    let eventName = 'touchstart';
    window.addEventListener(
      eventName,
      function onFirstTouch() {
        setCanHover(false);
        window.removeEventListener(eventName, onFirstTouch, false);
      },
      false
    );
  }, []);
  return canHover;
};

export const useHovered = () => {
  const [hovering, setHovering] = useState(false);
  const canHover = useCanHover();

  return {
    value: hovering,
    setValue: setHovering,
    bind: canHover
      ? {
          onMouseOver: () => setHovering(true),
          onMouseLeave: () => setHovering(false)
        }
      : {
          onClick: () => setHovering(h => !h)
        }
  };
};

export const useVisiblePose = initial => {
  let visible = 'visible';
  let hidden = 'hidden';
  const { setPose, pose, ...rest } = usePose(initial ? visible : hidden, [hidden, visible]);
  return [pose, v => setPose(v ? visible : hidden), rest];
};

/*export const useGoogleAnalytics = (id, startLoading) => {
  if (!id) {
    return;
  }

  useEffect(
    () => {
      if (startLoading) {
        let script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = `https://www.googletagmanager.com/gtag/js?id=${id}`;
        document.body.appendChild(script);
        window.dataLayer = window.dataLayer || [];

        function gtag() {
          window.dataLayer.push(arguments);
        }

        gtag('js', new Date());
        gtag('config', id, {
          anonymize_ip: true,
          cookie_expires: 0
        });
      }
    },
    [startLoading]
  );
};*/

export const useWindowSize = () => {
  const isClient = typeof window === 'object';

  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return false;
    }

    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
};

export const usePose = (initial, poses = {}) => {
  const [pose, setPose] = useState(initial);
  return { pose, setPose, poses };
};

export const useFindElementCenter = elementRef => {
  const [windowSize, setWindowSize] = useState();
  useEffect(() => {
    const { offsetTop, offsetLeft, offsetWidth, offsetHeight } = elementRef.current;
    setWindowSize({
      x: window.innerWidth / 2 - offsetWidth / 2 - offsetLeft,
      y: window.innerHeight / 2 - offsetHeight / 2 - offsetTop
    });
  }, []);
  return windowSize;
};

export const useMousePosition = shouldTrack => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const canHover = useCanHover();

  useEffect(() => {
    if (canHover && shouldTrack) {
      const handler = ({ clientX, clientY }) => {
        setMousePosition({
          x: clientX,
          y: clientY
        });
      };
      window.document.addEventListener('mousemove', handler);
      return () => window.document.removeEventListener('mousemove', handler);
    }
  }, [canHover, shouldTrack]);

  return canHover ? mousePosition : {};
};

export const useChangeIcon = () => {
  const [active, setActive] = useState(false);

  const logSizePosition = e => {
    if (window.innerWidth > 1000) {
      setActive(false);
    } else {
      setActive(true);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', logSizePosition);
    console.log('Created');
    return () => {
      console.log('Cleaned up');
      window.removeEventListener('resize', logSizePosition);
    };
  }, []);
  return active;
};

export const useChangeSlideValue = () => {
  const [slides, setActive] = useState(3);

  const logSizePosition = e => {
    if (window.innerWidth < 700) {
      setActive(1);
    } else if (window.innerWidth > 700 && window.innerWidth < 1300) {
      setActive(2);
    } else if (window.innerWidth > 1300 && window.innerWidth < 1800) {
      setActive(3);
    } else {
      setActive(4);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', logSizePosition);
    console.log('Created');
    return () => {
      console.log('Cleaned up');
      window.removeEventListener('resize', logSizePosition);
    };
  }, []);
  return slides;
};

export const useChangeStackValue = () => {
  const [stack, setActive] = useState(7);

  const logSizePosition = e => {
    if (window.innerWidth < 500) {
      setActive(2);
    } else if (window.innerWidth > 500 && window.innerWidth < 1000) {
      setActive(4);
    } else if (window.innerWidth > 1000 && window.innerWidth < 1500) {
      setActive(6);
    } else {
      setActive(10);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', logSizePosition);
    console.log('Created');
    return () => {
      console.log('Cleaned up');
      window.removeEventListener('resize', logSizePosition);
    };
  }, []);
  return stack;
};
