import React, { useState, useEffect } from 'react';

//styles
import * as S from './styles';

//maps
import MapGL, { Marker, Popup, NavigationControl } from 'react-map-gl';

const TOKEN =
  'pk.eyJ1Ijoid2FkYWhlc2FtMjEiLCJhIjoiY2pyODJhMDhjMDI2ZTQzb2RkNjgxMHY0diJ9.W9cODURNmVYUekoS1b2LkQ';

const navStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  padding: '10px'
};

// Nepal:
// latitude: 27.6904817,
// longitude: 85.2789809,
// zoom: 15,
// bearing: 0,
// pitch: 0,
// width: 500,
// height: 500

function Window({ night, ...rest }) {
  const [viewport, setViewPort] = useState({
    latitude: 32.8283991,
    longitude: -97.0121873,
    zoom: 15,
    bearing: 0,
    pitch: 0,
    width: 500,
    height: 500
  });

  return (
    <S.Window {...rest}>
      <MapGL
        {...viewport}
        mapStyle="mapbox://./styles/mapbox/dark-v9"
        mapboxApiAccessToken={TOKEN}
        onViewportChange={viewport => setViewPort(viewport)}
      >
        <Marker latitude={viewport.latitude} longitude={viewport.longitude}>
          <div style={{ background: '#B23634', width: 20, height: 20, borderRadius: '50%' }} />
        </Marker>
        {/* <div className="nav" style={navStyle}>
          <NavigationControl />
        </div> */}
      </MapGL>
    </S.Window>
  );
}

export default Window;
