import styled from 'styled-components';
import { Horizontal as $Horizontal, Vertical as $Vertical } from './flex-components';
import {hover} from "./mixins";

export const Horizontal = $Horizontal;
export const Vertical = $Vertical;

export const Space = styled('div')(
  {
    height: 15,
    width: 15
  },
  ({ huge }) => ({
    ...(huge && {
      height: 100,
      width: 100
    })
  }),
  ({ medium }) => ({
    ...(medium && {
      height: 40,
      width: 40
    })
  }),
  ({ small }) => ({
    ...(small && {
      height: 20,
      width: 20
    })
  }),
  ({ widther }) => ({
    ...(widther && {
      //height: 20,
      width: 20
    })
  })
);


export const Hover = styled.span(
  {
    backgroundColor: 'rgba(255,255,255, 0.2)',
    padding: `2px 7px`,
    borderRadius: 5,
    transition: 'all 150ms linear',
    ...hover({
      backgroundColor: 'rgba(255, 255, 255, 0.3)'
    })
  }
);
