import React, { useState } from 'react';

//components
import Stacker from './stacker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faNode,
  faReact,
  faHtml5,
  faAndroid,
  faApple,
  faDocker,
  faAws
} from '@fortawesome/free-brands-svg-icons';
import Slider from 'react-slick';

//hooks
import { useChangeStackValue, useChangeSlideValue } from '../../utils/hooks';

//styles
import * as S from './styles';
import * as A from './../../styles/shared-components';

//icons
import faMobile from '../../icons/mobile.svg';
import faDesktop from '../../icons/desktop.svg';
import faStack from '../../icons/stack.svg';
import faChecklist from '../../icons/checklist.svg';

function Stack({}) {
  //custom hooks
  const stacked = useChangeStackValue();
  const slides = useChangeSlideValue();

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: slides + 1,
    slidesToScroll: 1,
    adaptiveHeight: true
  };

  return (
    <S.Content>
      <center>
        <h2>Our Stack</h2>
      </center>
      <S.MobileWeb>
        <Stacker
          mainIcon={faMobile}
          title="Mobile Development"
          content="SatyaTech develop mobile apps for all contemporary 
mobile platforms.The team builds 
native and cross-platform solutions."
        />
        <Stacker
          mainIcon={faDesktop}
          title="Web Development"
          content="SatyaTech offers various
          CMS and frameworks based websites to complex
          ERP/CRM solutions and e-commerce stores."
        />
      </S.MobileWeb>

      <S.DesignQuality>
        <Stacker
          mainIcon={faStack}
          title="Product Design"
          content="We create design given the product audience,
          goal, business domain, technology and
          the platform for the solution."
        />
        <Stacker
          mainIcon={faChecklist}
          title="Quality Assurance"
          content="Our QA team ensures high standard of 
          quality of products delivered. We provide continuos support with timely updates.
          "
        />
      </S.DesignQuality>
      {stacked == 10 && <A.Space huge />}
      {stacked == 6 && <A.Space medium />}
      {stacked == 4 && <A.Space small />}
      {stacked > 4 ? (
        <S.ProgLang>
          <FontAwesomeIcon icon={faHtml5} size="4x" />
          <FontAwesomeIcon icon={faNode} size="4x" />
          <FontAwesomeIcon icon={faReact} size="4x" />
          <FontAwesomeIcon icon={faAndroid} size="4x" />
          <FontAwesomeIcon icon={faApple} size="4x" />
          <FontAwesomeIcon icon={faDocker} size="4x" />
          <FontAwesomeIcon icon={faAws} size="4x" />
        </S.ProgLang>
      ) : (
        <center>
          <S.Slider>
            <Slider {...settings}>
              <FontAwesomeIcon icon={faHtml5} size="4x" />
              <FontAwesomeIcon icon={faNode} size="4x" />
              <FontAwesomeIcon icon={faReact} size="4x" />
              <FontAwesomeIcon icon={faAndroid} size="4x" />
              <FontAwesomeIcon icon={faApple} size="4x" />
              <FontAwesomeIcon icon={faDocker} size="4x" />
              <FontAwesomeIcon icon={faAws} size="4x" />
            </Slider>
          </S.Slider>
        </center>
      )}
    </S.Content>
  );
}

export default Stack;
