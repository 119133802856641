import React, { useRef, useState } from 'react';

//styles
import * as S from './styles';
import * as A from '../../styles/shared-components';
import themes from '../../styles/themes';
//import "./slider.scss";

//icons
import fafacebook from '../../icons/facebook.svg';
import fainstagram from '../../icons/instagram.svg';
//import fainstagram from '../../icons/instagram.svg';
import fatwitter from '../../icons/twitter.svg';
import falinkedin from '../../icons/linkedin.svg';

//components
import MenuBar from '../MenuBar';
import ContactButton from '../ContactButton';
import Background from '../Background';
import { ThemeProvider } from 'styled-components';
import Stack from '../Stack';
import Work from '../Work';
import Clients from '../Clients';
import Contact from '../Contact';
import SwipeableViews from 'react-swipeable-views-react-18-fix';
import { bindKeyboard } from 'react-swipeable-views-utils';
import { motion } from 'framer-motion'

//hooks
// import {
//   //useGoogleAnalytics,
//   useHovered,
//   useFindElementCenter,
//   useMousePosition,
//   useCanHover
// } from '../../utils/hooks';

import useIntroAnimation from './use-intro-animation';
import { AddressView } from '../AddressView/AddressView.component';


//env
//const { REACT_APP_ANALYTICS_ID } = process.env;

const AutoPlaySwipeableViews = bindKeyboard(SwipeableViews);

function Home() {
  const [index, setIndex] = useState(0);

  // refs
  const contentRef = useRef();

  //custom hooks
  const { isAnimationDone, homePose } = useIntroAnimation(false);

  // side effects
  //useGoogleAnalytics(REACT_APP_ANALYTICS_ID, isAnimationDone);

  // computed
  const isBig = window.innerWidth > 100;

  const handleChangeIndex = newIndex => {
    setIndex(newIndex);
  };

  const social1 = async () => {
    window.open(`https://facebook.com/satyatech`);
  };
  const social2 = async () => {
    window.open(`https://instagram.com/satyatech`);
  };
  const social3 = async () => {
    window.open(`https://twitter.com/satyatech`);
  };
  const social4 = async () => {
    window.open(`https://www.linkedin.com/company/satyawati-llc`);
  };

  return (
    <ThemeProvider theme={themes['dark']}>
      <S.Home>
        <Background night={true} startLoadingLight={isAnimationDone} show={isBig} />

        <MenuBar
          className="menubar"
          setIndex={setIndex}
        />

        <S.Content ref={contentRef}>
          {/* {index} */}
          <S.TextContent isAnimationDone={isAnimationDone} pose={homePose}>
            {/* <S.Title> SatyaTech </S.Title> */}
            <S.Subtitle>
              <AutoPlaySwipeableViews
                resistance
                className="tabs-content"
                //interval={30000}
                //enableMouseEvents={true}
                index={index}
                onChangeIndex={handleChangeIndex}
                slideStyle={S.SlideStyle}
              >
                <S.Satya>
                  <S.MainText>
                    <div>
                      <center>
                        <div>
                          <h2>We're an independent development agency.</h2>
                          <span>
                            We understand the intricacies of web and mobile development inside-out. That's why
                            we build rock-solid Mobile and Web applications enriched with UX/UI along with
                            providing continuous support and services with digital marketing and
                            optimization.
                          </span>
                        </div>
                        <A.Space />
                        <ContactButton startLoading={isAnimationDone} setIndex={setIndex} />
                      </center>
                    </div>
                  </S.MainText>
                </S.Satya>

                <Stack />

                <Work />

                <Clients />

                <div>
                  <center>
                    <h2>Contact Us</h2>
                  </center>
                  <Contact />
                </div>

              </AutoPlaySwipeableViews>
            </S.Subtitle>

            <A.Space />
          </S.TextContent>
        </S.Content>
        <S.Footer
          as={motion.div}
          variants={{
            visible: { opacity: 1, y: 0 },
            hidden: { opacity: 0, y: 30 }
          }}
          initial="hidden"
          animate="visible"
        >
          <AddressView/>
          <S.CopyrightText>Copyright © 2023, Satyawati LLC</S.CopyrightText>

          {/*
            <S.Links2>
              <S.Social
                onClick={social1}
                role="button"
                tabIndex={0}
                onKeyPress={e => {
                  if ((e.which === 13 || e.which === 32)) {
                    social1(e);
                  }
                }}
              >
                <S.SocialIcon icon={fafacebook} />
              </S.Social>
              <S.Social
                onClick={social2}
                role="button"
                tabIndex={0}
                onKeyPress={e => {
                  if ((e.which === 13 || e.which === 32)) {
                    social2(e);
                  }
                }}
              >
                <S.SocialIcon icon={fainstagram} />
              </S.Social>
              <S.Social
                onClick={social3}
                role="button"
                tabIndex={0}
                onKeyPress={e => {
                  if ((e.which === 13 || e.which === 32)) {
                    social3(e);
                  }
                }}
              >
                <S.SocialIcon icon={fatwitter} />
              </S.Social>
              <S.Social
                onClick={social4}
                role="button"
                tabIndex={0}
                onKeyPress={e => {
                  if ((e.which === 13 || e.which === 32)) {
                    social4(e);
                  }
                }}
              >
                <S.SocialIcon icon={falinkedin} />
              </S.Social>
            </S.Links2>
            <S.Links>
              <S.Link href="mailto:contact@satyatech.xyz">Mail Us</S.Link>
              <S.Link onClick={() => handleChangeIndex(0)}>Home</S.Link>
              <S.Link onClick={() => handleChangeIndex(1)}>Services</S.Link>
              <S.Link onClick={() => handleChangeIndex(2)}>Work</S.Link>
              <S.Link onClick={() => handleChangeIndex(3)}>Clients</S.Link>
              <S.Link onClick={() => handleChangeIndex(4)}>Contact us</S.Link>
            </S.Links> 
          */}
        </S.Footer>
      </S.Home>
    </ThemeProvider>
  );
}

export default Home;
