import React, { useRef, useState } from 'react';

//components
import axios from 'axios';

//styles
import * as S from './styles';
import * as A from './../../styles/shared-components';

//hooks
import { useChangeIcon } from '../../utils/hooks';

//icons
import faSend from '../../icons/send.svg';

//maps
import ReactMapGL, { Marker, Popup, NavigationControl } from 'react-map-gl';

const TOKEN =
  'pk.eyJ1Ijoid2FkYWhlc2FtMjEiLCJhIjoiY2pyODJhMDhjMDI2ZTQzb2RkNjgxMHY0diJ9.W9cODURNmVYUekoS1b2LkQ';

const navStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  padding: '10px'
};

function Contact({}) {
  //custom hooks
  const isChangeSize = useChangeIcon();

  const [name, setName] = useState(``);
  const [email, setEmail] = useState(``);
  const [subject, setSubject] = useState(``);
  const [message, setMessage] = useState(``);
  const [error, setError] = useState(``);
  const [output, setOutput] = useState(false);
  const [server, setServer] = useState(0);
  const [marker, setMarker] = useState({
    latitude: 32.8283991,
    longitude: -97.0121873
  });

  const [viewport, setViewPort] = useState({
    latitude: 32.8283991,
    longitude: -97.0121873,
    zoom: 15,
    bearing: 0,
    pitch: 0,
    width: isChangeSize ? window.innerWidth : 500, //Adjust to Fix during responsiveness
    height: 500
  });

  const checkFormErrors = async (name, email, message, subject) => {
    if (name.length < 2) {
      setOutput(output => false);
      setError('Name needs to be at least 2 characters.');
    } else if (email.length < 10) {
      setOutput(output => false);
      setError('Email needs to be at least 10 characters.');
    } else if (subject.length < 5) {
      setOutput(output => false);
      setError('Subject needs to be at least 5 characters.');
    } else if (message.length < 5) {
      setOutput(output => false);
      setError('Message needs to be at least 5 characters.');
    } else {
      setOutput(output => true);
    }
  };

  const send = async () => {
    const url = 'https://satyatech.xyz:4000/video-pasal-support';
    const data = {
      name: name,
      email: email,
      query: message,
      subject: subject
    };
    checkFormErrors(name, email, message, subject);
    if (output == true) {
      axios.post(url, data).then(result => {
        console.log(result);
        setServer(server => result.data.success);
        //setOutput(output => true);
      });
    }
  };

  const change1 = async () => {
    setViewPort({
      latitude: 32.8283991,
      longitude: -97.0121873,
      zoom: 16,
      bearing: 0,
      pitch: 0,
      width: isChangeSize ? window.innerWidth : 500,
      height: 500
    });
    setMarker({
      latitude: 32.8283991,
      longitude: -97.0121873
    });
  };

  const change2 = async () => {
    setViewPort({
      latitude: 27.6904817,
      longitude: 85.2789809,
      zoom: 16,
      bearing: 0,
      pitch: 0,
      width: isChangeSize ? window.innerWidth : 500,
      height: 500
    });
    setMarker({
      latitude: 27.6904817,
      longitude: 85.2789809
    });
  };

  const handleClick = async e => {
    console.log('e');
    setMarker({
      latitude: e.lngLat[1],
      longitude: e.lngLat[0]
    });
  };

  return (
    <S.Contact>
      {/* <S.Map>
        <ReactMapGL
          {...viewport}
          mapStyle="mapbox://styles/mapbox/dark-v9"
          mapboxApiAccessToken={TOKEN}
          onViewportChange={viewport => setViewPort(viewport)}
          //onClick={handleClick}
        >
          <Marker
            latitude={parseFloat(marker.latitude)}
            longitude={parseFloat(marker.longitude)}
            //offsetLeft={-20}
            //offsetTop={-10}
          >
            <span className="pin" />
            <div style={{ background: '#4682B4', width: 20, height: 20, borderRadius: '50%' }} />
          </Marker>

          <Marker latitude={viewport.latitude} longitude={viewport.longitude}>
            <div style={{ background: '#B23634', width: 20, height: 20, borderRadius: '50%' }} />
          </Marker>
          <div className="nav" style={navStyle}>
            <NavigationControl />
          </div>
        </ReactMapGL>
      </S.Map> */}
      <A.Space widther />
      <S.Content>
        <div>
          <br />
          <span>Satyawati LLC</span>
          <br />
          <a href="tel:+15127665597"> Tel: +1-(512)-766-5597</a>
          <br />
        </div>
        <S.USNep>
          <S.Country onClick={change1} role="USA" tabIndex={0}>
            <span>SatyaTech - USA</span>
          </S.Country>
          <S.Country onClick={change2} role="Nepal" tabIndex={0}>
            <span>SatyaTech - Nepal</span>
          </S.Country>
        </S.USNep>
        <A.Space small />
        <S.NameEmail>
          <S.Input
            placeholder="Enter your Name"
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck={false}
            //value={name}
            onChange={e => setName(e.target.value)}
          />
          <A.Space small />
          <S.Input
            placeholder="Enter your Email"
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck={false}
            //value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </S.NameEmail>

        <A.Space small />
        <S.Input
          placeholder="Enter the Subject"
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck={false}
          //value={subject}
          onChange={e => setSubject(e.target.value)}
        />

        <A.Space small />
        <S.InputMessage
          placeholder="Enter the Message"
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck={false}
          //value={message}
          onChange={e => setMessage(e.target.value)}
        />

        <center>
          <S.Button
            onClick={send}
            role="button"
            tabIndex={0}
            onKeyPress={e => {
              if ((e.which === 13 || e.which === 32) ) {
                send(e);
              }
            }}
          >
            <S.AIcon icon={faSend} />
            <span>Send</span>
          </S.Button>
        </center>
        {server == 1 ? (
          <center>
            <p>Message sent sucessfully!</p>
          </center>
        ) : null}
        {output ? null : (
          <center>
            <p>{error}</p>
          </center>
        )}
        <A.Space small />
      </S.Content>
    </S.Contact>
  );
}

export default Contact;
