import React, { useState, useEffect } from 'react';
import * as S from './styles';
import { motion } from 'framer-motion'

//hooks
import { useChangeIcon } from '../../utils/hooks';

//components
//import { StickyContainer, Sticky } from 'react-sticky';

//icons
import SatyaTech from '../../icons/SatyaTech2.png';
import SatyaTechM from '../../icons/SatyaTech3.png';

function MenuBar({ className, initialPose, selected, setIndex, onClick, mainIcon }) {
  
  //custom hooks
  const isuseChangeIcon = useChangeIcon();

  return (
    <S.MenuBar 
      className={className} 
      as={motion.div}
      variants={{
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: -30 }
      }}
      initial="hidden"
      animate="visible"
      >
      <S.Header tabIndex={0}>
        {isuseChangeIcon ? (
          <img src={SatyaTechM} alt="logo" width="50" height="auto" onClick={() => setIndex(0)} />
        ) : (
          <img src={SatyaTech} alt="logo" width="220" height="auto" onClick={() => setIndex(0)} />
        )}
      </S.Header>
      <S.Icons>
        <S.Item>
          <S.Link onClick={() => setIndex(0)}>Home</S.Link>
          <S.Link onClick={() => setIndex(1)}>Services</S.Link>
          <S.Link onClick={() => setIndex(2)}>Work</S.Link>
          <S.Link onClick={() => setIndex(3)}>Client</S.Link>
          <S.Link onClick={() => setIndex(4)}>Contact</S.Link>
        </S.Item>
      </S.Icons>
    </S.MenuBar>
  );
}

export default MenuBar;
