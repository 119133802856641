import React, { useEffect, useState } from 'react';

//components
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ImageContentHover from './../../components/Hover';

//hooks
import { useWindowSize, useChangeSlideValue } from '../../utils/hooks';

//styles
import styled from 'styled-components';
import flex from './../../styles/flex';
import { isHorizontal } from './../../styles/responsive';

//image
import SS1 from './../../images/window-image.png';
import SS2 from './../../images/window-image2.png';
import SS3 from './../../images/window-image3.png';
import VideoPasalItems from './../../images/VideoPasalItems.png';

function Work({}) {
  //custom hooks
  const slides = useChangeSlideValue();
  const size = useWindowSize();

  const Content = styled.div({
    ...flex.centerVertical,
    ...flex.centerHorizontal,
    maxWidth: size > 1000 && slides == 4 ? '1030px' : slides == 3 ? '680px' : slides == 2 ? '440px' : '220px',
    [isHorizontal]: {
      maxWidth: '220px'
    }
  });

  const Content2 = styled.div({
    ...flex.centerVertical,
    ...flex.centerHorizontal,
    maxWidth: '900px',
    [isHorizontal]: {
      maxWidth: '220px'
    }
  });

  var settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: slides,
    slidesToScroll: 1,
    adaptiveHeight: true
  };

  return (
    <>
      {size.width < 1000 ? (
        <Content>
          <center>
            <h2>Our Work</h2>
            {/* <div>
          {size.width}px / {size.height}px
        </div> */}
          </center>
          <Slider {...settings}>
            <div id="slide">
              <ImageContentHover
                //className="image-cover"
                tileWidth="210px"
                tileHeight="auto"
                image={SS1}
                content={{
                  title: 'VideoPasal',
                  body: 'Premium Nepali Content Worldwide'
                }}
                effect="fadeIn"
              />
            </div>
            <div id="slide">
              <ImageContentHover
                className="image-cover"
                tileWidth="210px"
                tileHeight="auto"
                image={SS2}
                content={{
                  title: 'VideoPasal',
                  body: 'Premium Nepali Content Worldwide'
                }}
                effect="fadeIn"
              />
            </div>
            <div id="slide">
              <ImageContentHover
                className="image-cover"
                tileWidth="210px"
                tileHeight="auto"
                image={SS3}
                content={{
                  title: 'VideoPasal',
                  body: 'Premium Nepali Content Worldwide'
                }}
                effect="fadeIn"
              />
            </div>
            <div id="slide">
              <ImageContentHover
                className="image-cover"
                tileWidth="210px"
                tileHeight="auto"
                image={SS1}
                content={{
                  title: 'VideoPasal',
                  body: 'Premium Nepali Content Worldwide'
                }}
                effect="fadeIn"
              />
            </div>
          </Slider>
        </Content>
      ) : (
        <Content2>
          <center>
            <h2>Our Work</h2>
            <img src={VideoPasalItems} style={{ width: '800px', height: 'auto', marginLeft: '6rem' }} alt="1" />
          </center>
        </Content2>
      )}
    </>
  );
}

export default Work;
