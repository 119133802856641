import React from 'react';

//import { LoadScript } from 'components/Script';
import * as S from './styles';

//icons
import faApple from '../../icons/envelope.svg';
//import { isDev } from '../../utils/dev-prod';

//component
//import { isAndroid, isIOS, isBrowser } from 'react-device-detect';

//env
//const { REACT_APP_PADDLE_VENDOR, REACT_APP_PADDLE_PRODUCT_ID } = process.env;

function ContactButton({ startLoading, setIndex }) {
  const contacter = async () => {
    setIndex(4);
  };

  return (
    <S.Content>
      <br />
      <S.ContactButton
        onClick={contacter}
        role="button"
        tabIndex={0}
        onKeyPress={e => {
          if ((e.which === 13 || e.which === 32) ) {
            contacter(e);
          }
        }}
      >
        <S.AppleIcon icon={faApple} />
        <span>Contact Us</span>
      </S.ContactButton>
    </S.Content>
  );
}

export default ContactButton;
