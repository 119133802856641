import styled from 'styled-components';
import flex from './../../styles/flex';
import FontAwesomeIcon from './../../icons/Icon.js';
import { ELEMENTS, zIndexFor } from './../../styles/zindex';


import { applyTheme, getThemeColor, hover, when, whenNot } from './../../styles/mixins';

export const MenuBar = styled.div(({ theme }) => ({
  position: 'relative',
  ...flex.horizontal,
  ...flex.centerHorizontalV,
  // ...flex.justifyEnd,
  justifyContent: 'space-between',
  ...zIndexFor(ELEMENTS.MENUBAR),
  height: 80, //To increase menu size
  width: '100%',
  backgroundColor: theme.name === 'dark' ? 'rgba(0, 0, 0, 0.1)' : '#f2f2f2',
  opacity: 0
}));

export const Icons = styled.div({
  ...flex.horizontal,
  ...flex.centerHorizontalV,
  height: '100%',
  marginRight: 20
});

export const Header = styled.div({
  ...flex.horizontal,
  ...flex.centerHorizontal,
  userSelect: 'none',
  cursor: 'default',
  minWidth: 25,
  // padding: `0 5px`,
  height: '100%',
  marginLeft: 10,
  transition: 'all 150ms linear'
});

export const Item = styled.div(
  {
    ...flex.horizontal,
    ...flex.centerHorizontal,
    userSelect: 'none',
    cursor: 'default',
    minWidth: 25,
    padding: `0 8px`,
    height: '100%',
    transition: 'all 150ms linear'
  },
  ({ selected, onClick, theme }) => ({
    ...(selected && {
      backgroundColor: '#2b85f2'
    }),
    ...(onClick &&
      !selected && {
        cursor: 'pointer',
        
        '&:hover': {
          backgroundColor: theme.name === 'dark' ? '#193046' : '#d7dee0'
        }
      })
  })
);

export const Link = styled.a(
  {
    textDecoration: 'none',
    borderBottom: '1px solid transparent',
    transition: 'all 100ms linear',
    marginRight: 10,
    '&:last-of-type': {
      margin: 0
    },
    fontSize: 18, //to increase Menu item size
    ...hover({
      //color: '#4682B4',
      borderBottom: '1px solid #4682B4'
    })
  },
  getThemeColor('text')
);

export const Icon = styled(FontAwesomeIcon)(({ theme }) => ({
  width: `25px !important`, //to increase Icon Size
  height: `25px !important`,
  fill: theme.name === 'dark' ? 'white' : 'black'
}));
