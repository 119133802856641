import styled from 'styled-components';
import flex from './../../styles/flex';
import WindowShell from './../Window';

import { applyTheme, getThemeColor, hover, when, whenNot } from './../../styles/mixins';
import { breakpoints, isHorizontal, smaller, smallerHeight } from './../../styles/responsive';
import { ELEMENTS, zIndexFor } from './../../styles/zindex';

//components
import Icon from './../../icons/Icon';

export const Text = styled.div(getThemeColor('text'));

export const SlideStyle= { 
  display: 'flex', 
  width: '100%', 
  justifyContent: 'center',
  alignItems: 'center' 
}

export const Satya = styled.div({
  display:'flex',
  ...flex.centerVertical,
  ...flex.centerHorizontal,
  // ...zIndexFor(ELEMENTS.CONTENT),
  //marginTop: 'auto',
  maxWidth: 1100,
  //padding: "10px",
  //marginBottom: 'auto',
  [isHorizontal]: {
    flexDirection: 'column',
    ...flex.centerVertical,
    maxWidth: 400
  }
});

export const ContentView = styled.div({
  //display: 'flex',
  //justifyContent: 'center',
  //alignItems: 'center'
})

export const MainText = styled.div({
  ...flex.centerVertical,
  ...flex.centerHorizontal,
  // ...zIndexFor(ELEMENTS.CONTENT),
  marginTop: 'auto',
  display: 'flex',
  //maxWidth: 1200,
  padding: '10px',
  marginBottom: 'auto',
  [smaller(1520)]: {
    maxWidth: 1200
  },
  [smaller(1150)]: {
    maxWidth: 900
  },
  [smaller(980)]: {
    maxWidth: 700
  },
  // [smaller(breakpoints.medium)]: {
  //   padding: '200px'
  // },
  [isHorizontal]: {
    flexDirection: 'column',
    ...flex.centerVertical,
    maxWidth: 400
  }
});

export const Home = styled.div({
  //...flex.vertical,
  //...flex.centerVertical,
  
  //flex: 1,
  //minHeight: '100vh',
  //minWidth: '100vw'
});

export const Subtitle = styled(Text)({
  fontSize: 25,
  fontWeight: 300,
  lineHeight: '40px',
  width: '100%',
  [smaller(950)]: {
    fontSize: 23
  },
  [smaller(breakpoints.medium)]: {
    fontSize: 22,
    padding: '200px'
  },
  [isHorizontal]: {
    fontSize: 20
    //lineHeight: '30px'
  }
});

export const Window = styled(WindowShell)(
  {
    transition: 'all 250ms linear',
    [isHorizontal]: {
      margin: 'auto',
      width: '100%'
    }
  },
  when('hovering', {
    transform: 'scale(1.03)'
    //boxShadow: '0 20px 25px 12px rgba(0, 0, 0, 0.22)'
  })
);

export const Title = styled(Text)({
  fontWeight: 500,
  fontSize: 55,
  lineHeight: '50px',
  transition: 'all 250ms linear',
  cursor: 'default'
});

export const MainSection = styled.div({
  //position: 'relative',
  //...flex.vertical,
  //...flex.centerHorizontal,
  //width: '100%',
  //minHeight: '100vh',
  //flex: 1,
  //...zIndexFor(ELEMENTS.MAIN_SECTION)
});

export const Padding = styled.div({
  padding: '30px 90px',
  [smaller(breakpoints.large)]: {
    padding: '30px 50px'
  },
  [smaller(breakpoints.medium)]: {
    padding: '20px 40px'
  },
  [isHorizontal]: {
    padding: '30px 15px'
  }
});

export const Footer = styled.div(
  {
    position: 'fixed',
    ...zIndexFor(ELEMENTS.FOOTER),
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    transition: 'all 100ms linear',
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    padding: '7px 15px',
    overflow: 'hidden',
    left: 0,
    right: 0,
    bottom: 0,
    margin: 'auto',
    opacity: 0
  },
  ({ theme }) => ({
    [smaller(750)]: {
      position: 'relative',
      ...zIndexFor(ELEMENTS.FOOTER),
      width: '100%',
      backgroundColor: theme.name === 'dark' ? '#243244' : '#b2cc9f'
    },
    [smallerHeight(715)]: {
      position: 'relative',
      ...zIndexFor(ELEMENTS.FOOTER),
      width: '100%'
    }
  })
);

export const Links = styled.div({
  ...flex.horizontal,
  ...flex.centerHorizontal,
  ...flex.centerVertical
});

export const Links2 = styled.div({
  ...flex.horizontal,
  ...flex.centerHorizontal,
  marginBottom: "0.2rem"
});

export const Link = styled.a(
  {
    textDecoration: 'none',
    borderBottom: '1px solid transparent',
    transition: 'all 100ms linear',
    marginRight: 20,
    '&:last-of-type': {
      margin: 0
    },
    fontSize: 13,
    ...hover({
      borderBottom: '1px solid white'
    })
  },
  getThemeColor('text')
);

export const Social = styled.button(
  {
    ...flex.horizontal,
    ...flex.centerHorizontal,
    userSelect: 'none',
    border: 'none',
    // fontSize: 18,
    // borderRadius: 4,
    // maxWidth: 20,
    // padding: 15,
    //width: '100%',
    transition: 'all 150ms linear',
    boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.0)'
  },
  applyTheme('socialicon'),
  when('disabled', {
    opacity: 0.5,
    cursor: 'not-allowed'
  }),
  ({ theme }) => ({
    ...(!theme.disabled && {
      ...hover({
        background: theme.button.backgroundHover,
        borderRadius: "10rem"
      })
    })
  })
);

export const SocialIcon = styled(Icon)(
  {
    //marginRight: 15,
    // marginBottom: 8,
    width: 22,
    height: 'auto',
    position: 'relative',
    top: -1,
    opacity: 0.7
  },
  getThemeColor('text', 'fill')
);

export const Content = styled(Padding)({
  // ...flex.horizontal,
  ...zIndexFor(ELEMENTS.CONTENT),
  color: 'white',
  marginTop: 'auto',
  marginBottom: 'auto',
  [isHorizontal]: {
    flexDirection: 'column-reverse',
    ...flex.centerVertical
    //...flex.alignStart,
    //height: 50
  }
});

export const TextContent = styled.div(
  {
    ...flex.vertical,
    [isHorizontal]: {
      ...flex.centerVertical,
      textAlign: 'center'
    }
  },
  whenNot('isAnimationDone', {
    pointerEvents: 'none'
  })
);

export const CopyrightText = styled.p({
  color: 'white', 
  fontSize: '15px',
  padding: 0, 
  margin: 0, 
  textAlign: 'center'
})
