import React, { Fragment, useState } from 'react';

//styles
import * as S from './styles';

//images
import desertDark from './../../images/desert-dark.svg';

function Background({ show, night }) {
  const [desertLoaded, setDesertLoad] = useState(false);

  return (
    show && (
      <Fragment>
        <S.Desert show={night && desertLoaded} onLoad={() => setDesertLoad(true)} src={desertDark} />
        {/* <ReactSVG
          style={{
            position: 'fixed',
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            width: '100vw',
            maxWidth: '100vw',
            height: '100vh',
            overflow: 'hidden',
            objectPosition: 'top',
            objectFit: 'cover',
            transition: 'all 150ms ease-in',
            opacity: 0,
            backgroundColor: '#1a2837'
          }}
          className="web_background"
          desc="Description"
          evalScripts="always"
          src='./../../images/desert-dark.svg'
        /> */}
      </Fragment>
    )
  );
}

export default Background;
