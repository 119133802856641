import styled from 'styled-components';
import flex from './../../styles/flex';
import { applyTheme, getThemeColor, hover, when } from './../../styles/mixins';
import { breakpoints, isHorizontal, smaller, smallerHeight } from './../../styles/responsive';

//components
import Icon from './../../icons/Icon';

export const StackImage = styled(Icon)(
  {
    ...flex.centerVertical,
    marginRight: 15,
    width: 60,
    height: 70,
    position: 'relative',
    top: -1
  },
  getThemeColor('text', 'fill')
);

export const Button = styled.div({
  ...flex.horizontal,
  ...flex.centerHorizontal
});

export const Content = styled.div({
  ...flex.centerVertical,
  ...flex.centerHorizontal,
  [isHorizontal]: {
    fontSize: 20,
    //lineHeight: '30px'
  }
});

export const Stacker = styled.div({
  ...flex.centerVertical,
  ...flex.centerHorizontal,
  // ...zIndexFor(ELEMENTS.CONTENT),
  marginTop: 'auto',
  maxWidth: 800,
  padding: "10px",
  marginBottom: 'auto',
  [smaller(1520)]: {
    fontSize: 20,
    maxWidth: 700
  },
  [smaller(1150)]: {
    fontSize: 20,
    maxWidth: 500
  },
  [smaller(980)]: {
    fontSize: 20,
    maxWidth: 400
  },
  [smaller(breakpoints.medium)]: {
    fontSize: 22,
    maxWidth: 350
    // padding: "200px"
  },
  [isHorizontal]: {
    flexDirection: 'column',
    ...flex.centerVertical,
    maxWidth: 400
  }
});

export const MobileWeb = styled.div({
  ...flex.horizontal,
  //...flex.spaceAround,
  // ...zIndexFor(ELEMENTS.CONTENT),
  marginTop: 'auto',
  marginBottom: 'auto',
  padding: "1rem",
  // marginLeft: 50,
  // padding: "0rem 7rem 0rem 8rem",
  [isHorizontal]: {
    flexDirection: 'column',
    ...flex.centerVertical
  }
});

export const DesignQuality = styled.div({
  ...flex.horizontal,
  //...flex.spaceAround,
  // ...zIndexFor(ELEMENTS.CONTENT),
  marginTop: 'auto',
  marginBottom: 'auto',
  padding: "1rem",
  // marginLeft: 50,
  // padding: "0rem 7rem 0rem 8rem",
  [isHorizontal]: {
    flexDirection: 'column',
    ...flex.centerVertical
  }
});

export const ProgLang = styled.div({
  ...flex.horizontal,
  ...flex.spaceAround,
  width: '85%',
  // ...zIndexFor(ELEMENTS.CONTENT),
  margin: "0 auto",
  marginTop: 'auto',
  // marginLeft: 50,
  // padding: "0rem 7rem 0rem 8rem",
  marginBottom: 'auto',
  // [isHorizontal]: {
  //   flexDirection: 'column',
  //   ...flex.centerVertical
  // }
});

export const Window = styled.div({
  ...flex.horizontal,
  // ...zIndexFor(ELEMENTS.CONTENT),
  marginTop: 'auto',
  marginBottom: 'auto',
  [isHorizontal]: {
    flexDirection: 'column',
    ...flex.centerVertical
  }
});

export const Slider = styled.div({
  // ...flex.horizontal,
  //...flex.centerVertical,
  //...flex.centerHorizontal,
  //...flex.spaceBetween,
  //marginLeft: window.innerWidth/7,
  maxWidth: "250px"
  //  ...zIndexFor(ELEMENTS.CONTENT),
});
