import React, { useEffect, useState } from 'react';

//components
import Slider from 'react-slick';

//hooks
import { useChangeSlideValue } from '../../utils/hooks';

//styles
import styled from 'styled-components';
import flex from './../../styles/flex';
import { isHorizontal } from './../../styles/responsive';

//image
import OSR from './../../images/OSR-wBG.png';
import kChaiyo from './../../images/kChaiyo.jpg';
import Oxford from './../../images/OxfordSecurities.png';

function Clients({}) {
  //custom hooks
  const slides = useChangeSlideValue();

  const Content = styled.div({
    ...flex.centerVertical,
    ...flex.centerHorizontal,
    maxWidth: slides == 4 ? '1030px' : slides == 3 ? '800px' : slides == 2 ? '550px' : '250px',
    [isHorizontal]: {
      maxWidth: '250px'
    }
  });

  var settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: slides,
    slidesToScroll: 1,
    adaptiveHeight: true
  };

  return (
    <Content>
      <center>
        <h2>Our Clients</h2>
      </center>
      <Slider {...settings}>
        <div>
          <img src={OSR} style={{ width: '200px', margin: '30px', height: 'auto' }} alt="1" />
        </div>
        <div>
          <img src={Oxford} style={{ width: '200px', margin: '30px', height: 'auto' }} alt="2" />
        </div>
        <div>
          <img src={kChaiyo} style={{ width: '200px', margin: '30px', height: 'auto' }} alt="3" />
        </div>
        <div>
          <img src={OSR} style={{ width: '200px', margin: '30px', height: 'auto' }} alt="4" />
        </div>
        <div>
          <img src={Oxford} style={{ width: '200px', margin: '30px', height: 'auto' }} alt="5" />
        </div>
      </Slider>
    </Content>
  );
}

export default Clients;
