import styled from 'styled-components';
import flex from './../../styles/flex';
import { applyTheme, getThemeColor, hover, when } from './../../styles/mixins';

import { isHorizontal } from './../../styles/responsive';

//components
import Icon from './../../icons/Icon';

export const ContactButton = styled.button(
  {
    ...flex.horizontal,
    ...flex.centerHorizontal,
    userSelect: 'none',
    border: 'none',
    fontSize: 18,
    borderRadius: 4,
    maxWidth: 180,
    padding: 15,
    width: '100%',
    transition: 'all 150ms linear',
    boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.2)'
  },
  applyTheme('contactbutton'),
  when('disabled', {
    opacity: 0.5,
    cursor: 'not-allowed'
  }),
  ({ theme }) => ({
    ...(!theme.disabled && {
      ...hover({
        background: theme.button.backgroundHover
      })
    })
  })
);

export const AppleIcon = styled(Icon)(
  {
    marginRight: 15,
    width: 20,
    height: 20,
    position: 'relative',
    top: -1
  },
  getThemeColor('text', 'fill')
);

export const AndroidIcon = styled(Icon)(
  {
    marginRight: 15,
    width: 20,
    height: 20,
    position: 'relative',
    top: -1
  },
  getThemeColor('text', 'fill')
);

export const Content = styled.div({
  //...flex.horizontal,
  //...flex.centerHorizontal,
  // ...zIndexFor(ELEMENTS.CONTENT),
  //marginTop: 'auto',
  //marginBottom: 'auto',
  [isHorizontal]: {
    flexDirection: 'column-reverse',
    ...flex.centerVertical
  }
});
